<template>
  <div>
    <VList :rows="rows" :headers="headers">
      <template #title>
        {{ $t("app.recent_logins") }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :src="item.displayed_avatar" :name="item.displayed_user" />
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";

export default {
  components: {
    VList,
    VImage
  },
  props: {
    logins: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.users", 1),
        value: "displayed_user"
      },
      {
        text: t("app.username"),
        value: "displayed_username"
      },
      {
        text: t("app.login_time"),
        value: "displayed_login"
      },
      {
        text: t("app.ip_address"),
        value: "ip"
      }
    ];

    // METHODS
    const { getAvatarURL, formatDate } = useDisplay();

    const rows = computed(() => {
      return props.logins.map(login => ({
        ...login,
        displayed_user: `${login?.user?.firstname} ${login?.user?.lastname}`,
        displayed_username: login?.user?.username,
        displayed_avatar: getAvatarURL(login?.user?.avatar?.view_path),
        displayed_login: formatDate({
          date: login.logged_at,
          displayTime: true
        })
      }));
    });

    return {
      headers,
      rows
    };
  }
};
</script>
