<template>
  <div>
    <VTitle :title="$t('app.licenses', 2)" class="overview-title" />

    <License
      v-for="(license, index) in licenses"
      :key="`license-${index}`"
      :license="license"
      class="mb-5"
    />
  </div>
</template>

<script>
// Components
import License from "./License";
import VTitle from "@/components/VTitle";

export default {
  components: {
    License,
    VTitle
  },
  props: {
    licenses: {
      type: Array,
      default: () => []
    }
  }
};
</script>
