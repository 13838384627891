<template>
  <div>
    <VTitle :title="$t('app.dashboard')" class="route-title" />

    <div class="grid grid-cols-12 gap-8">
      <div class="col-span-12 xl:col-span-8">
        <SectionOverview :sections="sections" class="mb-10" />
        <RecentLogins :logins="logins" />
      </div>

      <Licenses class="col-span-12 xl:col-span-4" :licenses="licenses" />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import RecentLogins from "./RecentLogins";
import Licenses from "./Licenses";
import SectionOverview from "./SectionOverview";

export default {
  components: {
    VTitle,
    RecentLogins,
    SectionOverview,
    Licenses
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // DATA
    const data = ref({});

    // Composables
    const { request } = useRequest();

    // Computed
    const documentTitle = computed(
      () => `${t("app.dashboard")} - ${t("app.administration")}`
    );
    const logins = computed(() => data.value?.logins ?? []);
    const licenses = computed(() => data.value?.licenses ?? []);
    const sections = computed(() => [
      {
        title: t("app.users"),
        ...(data.value?.users ?? {})
      },
      {
        title: t("app.roles"),
        ...(data.value?.roles ?? {})
      },
      {
        title: t("app.departments"),
        ...(data.value?.departments ?? {})
      },
      {
        title: t("app.sites"),
        ...(data.value?.sites ?? {})
      }
    ]);

    // METHODS
    const getData = async () => {
      const response = await request({
        endpoint: "administration.dashboard"
      });

      data.value = response?.payload?.data ?? {};
    };

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      logins,
      licenses,
      sections
    };
  }
};
</script>
